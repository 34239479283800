<template>
  <section class="header bg-regular">
    <div class="header-container split">
      <div class="map-outer-container">
        <div class="screen"></div>
        <div class="map-container">
          <div v-if="showMap" aria-hidden= "true"
            tabindex="-1"
            id="mapid"
            :class="{'long-ip': isIPV6, 'connected': isVPNProtected}">
            <l-map ref="myMap"
                style="height: 100%"
                :zoom="zoom"
                :center="center"
                :options=mapOptions>
              <l-tile-layer @ready="emitMapIsReady()" ref="tiles"
                style="height: 100%; width: 100%"
                :url="url">
              </l-tile-layer>
              <l-marker v-if="markerLatLng"
                ref="marker"
                :lat-lng="markerLatLng"
                :icon="isVPNProtected ? tunnelBear : isGhost ? ghostBear : sheep">
                <l-popup ref="popup"
                  :content="showDetailedPopupContent ? showCountryName : showLocationData"
                  :options="popupOptions">
                </l-popup>
              </l-marker>
            </l-map>
            <img v-if="showMap" class="globe-shading" src="./images/globe-shading.svg" alt="">
          </div>
        </div>
      </div>
      <div v-if="showMap" class="left-side-copy">
        <h1>
          What's my IP?
        </h1>
        <template v-if="ip">
          <p class="ip-address">IP Address</p>
          <div :class="['ip-address-result', { 'small' : isIPV6 }]">
            <h2
              :aria-label="ip"
              :class="isVPNProtected ? 'protected' : isGhost ? 'ghost' : 'unprotected'">
              <div class="security-icon"
                aria-hidden="true">
              </div>
              <span aria-hidden="true">{{ ip }}</span>
            </h2>
          </div>
        </template>
        <h3 v-if="isVPNProtected"
          class="protected-result protected">
          Your location is private and encrypted with&nbsp;a&nbsp;bear!
        </h3>
        <h3 v-else-if="isGhost"
          class="protected-result ghost">
          Your are connected via ghost!
        </h3>
        <h3 v-else
          class="protected-result unprotected">
          Your IP Address is public. Advertisers can track your location and browsing!
        </h3>
        <template v-if="location">
          <p class="location">Location</p>
          <p v-if="!isVPNProtected" class="location-result">{{ `${location.city}, ${location.region}` }}</p>
          <p v-else class="location-result country-only">{{ country }}</p>
        </template>
        <p v-if="!isVPNProtected && serviceProvider" class="service-provider">{{ serviceProvider }}</p>
        <a href="https://www.tunnelbear.com"
          class="stay-private btn btn-lg">
            {{ isVPNProtected ? 'Learn about TunnelBear': 'Stay Private with TunnelBear' }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import * as DOMPurify from 'dompurify'
import L from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'

const axios = require('axios').default
const imageUrl = process.env.NODE_ENV === 'development' ? '/' : '/whats-my-ip/'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup
  },
  name: 'IPTracker',
  props: ['ghostIps', 'ctfIps'],
  data () {
    return {
      url: imageUrl + 'images/tiles/{z}/{x}/{y}.png',
      zoom: 3,
      center: undefined,
      mapOptions: {
        dragging: false,
        zoomControl: false,
        doubleClickZoom: false,
        touchZoom: false,
        scrollWheelZoom: false
      },
      sheep: L.icon({
        iconUrl: imageUrl + 'images/sheep.svg',
        iconSize: [45, 95],
        iconAnchor: [12, 60],
        shadowUrl: imageUrl + 'images/ellipse.svg',
        shadowSize: [68, 95],
        shadowAnchor: [28, 60]
      }),
      tunnelBear: L.icon({
        iconUrl: imageUrl + 'images/tunnelOn.svg',
        iconSize: [45, 95],
        iconAnchor: [12, 60],
        shadowUrl: imageUrl + 'images/ellipse.svg',
        shadowSize: [74, 95],
        shadowAnchor: [27, 60]
      }),
      ghostBear: L.icon({
        iconUrl: imageUrl + 'images/blackbear-ghost.svg',
        iconSize: [45, 95],
        iconAnchor: [12, 60],
        shadowUrl: imageUrl + 'images/noise-shadow.gif',
        shadowSize: [72, 72],
        shadowAnchor: [25, 50]
      }),
      showMap: false,
      markerLatLng: undefined,
      popupOptions: {
        closeButton: false,
        closeOnEscapeKey: false
      },
      popupLatLng: undefined,
      showDetailedPopupContent: false,
      isVPNProtected: false,
      isGhost: false,
      country: undefined,
      ip: undefined,
      location: undefined,
      serviceProvider: undefined
    }
  },
  created () {
    this.init()
  },
  beforeMount: function () {
    this.$emit('modifyLayout', {
      bgColor: 'bg-regular'
    })
  },
  computed: {
    isIPV6 () {
      return this.ip && this.ip.indexOf(':') > -1
    },
    showCountryName () {
      const data = DOMPurify.sanitize(this.country)
      return `<h3>${data}<h3/>`
    },
    showLocationData () {
      const city = DOMPurify.sanitize(this.location.city)
      const region = DOMPurify.sanitize(this.location.region)
      const serviceProvider = this.serviceProvider ? DOMPurify.sanitize(this.serviceProvider) : 'unknown'
      return `<h3>${city}, ${region}<h3/>
              <p>ISP: ${serviceProvider}</p>`
    }
  },
  methods: {
    removeTabStops () {
      const mapEls = this.$refs.myMap
      mapEls.$el.setAttribute('tabindex', '-1')
      const nodes = mapEls.$el.querySelectorAll('img.leaflet-marker-icon')
      nodes.forEach(node => {
        node.setAttribute('tabindex', '-1')
      })
    },
    emitMapIsReady () {
      setTimeout(() => {
        this.$emit('mapIsReady')
      }, 200)
    },
    initMap () {
      this.showMap = true
      // add 8 to lat to push ISP location down to make room for popup
      const lat = this.location.latitude + 8
      const lng = this.location.longitude
      this.center = [lat, lng]
      // subtract 8 from lat to line up marker with new ISP location
      this.markerLatLng = [(lat - 8), lng]
      if (this.isVPNProtected || this.isGhost) {
        this.showDetailedPopupContent = true
      }

      this.$nextTick(() => {
        this.$refs.marker.mapObject.openPopup()
        this.removeTabStops()
      })
    },
    async init () {
      const whoIsRequest = axios.get(process.env.VUE_APP_API_BASE_URL + '/getWhois')
      const locationRequest = axios.get(process.env.VUE_APP_API_BASE_URL + '/location')
      const VPNRequest = axios.get(process.env.VUE_APP_API_BASE_URL + '/isVPNConnected')

      try {
        const whoIsIsp = await whoIsRequest
        this.setIPAddress(whoIsIsp.data)
      } catch (err) {
        console.error('Error when requesting /getWhois', err)
      }

      try {
        const location = await locationRequest
        this.setLocation(location.data)
      } catch (err) {
        console.error('Error when requesting /location', err)
      }

      try {
        const isVPNConnected = await VPNRequest
        this.setVPNStatus(isVPNConnected.data)
      } catch (err) {
        console.error('Error when requesting /isVPNConnected', err)
      }

      this.initMap()
    },
    setIPAddress (ispData) {
      this.ip = ispData.IP
      this.serviceProvider = ispData.ISP
      if (this.ghostIps.includes(this.ip)) {
        this.isGhost = true
        this.setLocation({
          city: 'Liminalo',
          region: 'Ghostia',
          latitude: 26.5,
          longitude: -69.5
        })
        this.setVPNStatus({
          connected: 1,
          countryName: 'Ghostia'
        })
      } else if (this.ctfIps.includes(this.ip)) {
        this.isGhost = true
        this.setLocation({
          city: 'Undisclosed',
          region: 'Unknown',
          latitude: 26.5,
          longitude: -69.5
        })
        this.setVPNStatus({
          connected: 1,
          countryName: 'CTF'
        })
      }
    },
    setLocation (location) {
      this.location = location
    },
    setVPNStatus (isVPNConnected) {
      if (isVPNConnected.connected) {
        if (!this.isGhost) {
          this.isVPNProtected = true
        }
        this.country = isVPNConnected.countryName
      }
    }
  }
}
</script>

<style src="./IPTracker.styl" scoped lang="stylus"></style>
<style src="./IPTracker-map.styl" lang="stylus"></style>
