<template>
  <div :style="{padding: padding}">
    <img
      :style="{width: size}"
      class="spinner"
      src="./images/spinner.png" alt="" aria-hidden="true">
  </div>
</template>

<script>

export default {
  name: 'spinner',
  props: {
    size: {
      required: false,
      type: String
    },
    padding: {
      required: false,
      type: String
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped lang="stylus" src="./spinner.styl" >
