import { render, staticRenderFns } from "./spinner.vue?vue&type=template&id=2765a779&scoped=true"
import script from "./spinner.vue?vue&type=script&lang=js"
export * from "./spinner.vue?vue&type=script&lang=js"
import style0 from "./spinner.styl?vue&type=style&index=0&id=2765a779&prod&scoped=true&lang=stylus&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2765a779",
  null
  
)

export default component.exports