<template>
  <section id="site-footer">
    <div class="section-inner">
      <div class="container text-center">
        <a href="https://tunnelbear.com"><img src="./images/tb-logo-grey.svg" alt=""></a>
        <p class="fine-print-lg"><a href="https://tunnelbear.com/privacy-policy">Privacy Policy</a></p>
        <p class="fine-print-lg">&copy;{{new Date().getFullYear()}} TunnelBear LLC.</p>
        <template v-if="detailed">
          <p class="fine-print-lg">310 Spadina Ave, Suite 200<br>Toronto, Ontario, M5T 2E7</p>
          <p class="fine-print-lg"><a :href="'mailto:' + this.supportEmail">{{supportEmail}}</a></p>
        </template>
        <p v-else class="fine-print-lg">Grizzly regards from Toronto, Canada</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'site-footer',
  props: ['detailed'],
  data () {
    return {
      supportEmail: ''
    }
  },
  created: function () {
    this.supportEmail = 'support@tunnelbear.com'
  }
}
</script>

<style scoped lang="stylus" src="./footer.styl" >
