<template>
  <div>
    <spinner v-if="!error && !isLoaded" class="animation-spinner" size="60px" padding="6rem 0 0 0"/>
    <section class="error" v-if="error">
      <div class="container split">
        <div class="error-message">
          <h1 class="h2">
            Uh oh!  We're having trouble finding your IP address.
            <span>Please try again later.</span>
          </h1>
        </div>
        <div class="error-image">
          <img src="./images/hide-your-ip.svg" alt="">
        </div>
      </div>
    </section>
    <main v-else>
      <IPTracker
        :class="{'hidden':!isLoaded}"
        :ghostIps="['178.128.231.79', '165.227.30.149']"
        :ctfIps="['165.232.120.193', '178.128.239.78']"
        @mapIsReady="showPage()"
        @error="showError()"/>
    </main>
    <section v-if="isLoaded" class="install">
      <div class="install">
        <h2>How do I install a VPN?</h2>
        <ul class="split">
          <li>
            <img src="@/views/WhatsMyIP/images/growling-tunnelbear.svg" alt="">
            <div class="copy">
              <h4 class="h6">Download the app</h4>
              <p>TunnelBear apps are available for PC, Mac, iPhone and Android.</p>
            </div>
          </li>
          <li>
            <img src="@/views/WhatsMyIP/images/create-account-form.svg" alt="">
            <div class="copy">
              <h4 class="h6">Create an account</h4>
              <p>Use your email and create a password.</p>
            </div>
          </li>
          <li>
            <img src="@/views/WhatsMyIP/images/claw-toggle.svg" alt="">
            <div class="copy">
              <h4 class="h6">Connect</h4>
              <p>Tap the button to connect to the VPN.</p>
            </div>
          </li>
        </ul>
        <a href="https://tunnelbear.com"
          class="btn btn-lg">
            Get TunnelBear
        </a>
      </div>
    </section>
    <section v-if="isLoaded" class="faq">
      <div class="faq">
        <h2>Helping You Stay Private&nbsp;Online</h2>
        <ul class="split">
          <div class="top-row">
            <li>
              <img src="@/views/WhatsMyIP/images/roaring-bear-b.svg" alt="">
              <div class="copy">
                <h4 class="h6">Get to Know TunnelBear</h4>
                <p>As we’ve grown, we’ve helped everyone, from security experts to novices, protect their online privacy and fight censorship.</p>
                <a href="https://www.tunnelbear.com/blog/get-to-know-tunnelbear/">Learn More <span aria-hidden="true">></span></a>
              </div>
            </li>
            <li>
              <img src="@/views/WhatsMyIP/images/stop-surveillance.svg" alt="">
              <div class="copy">
                <h4 class="h6">Iran and the Four Stages of Censorship</h4>
                <p>TunnelBear has been around for almost ten years. For much of this time, we’ve been providing free bandwidth support to countries experiencing censorship crises.</p>
                <a href="https://www.tunnelbear.com/blog/iran-and-the-four-stages-of-censorship/">Learn More <span aria-hidden="true">></span></a>
              </div>
            </li>
          </div>
          <div class="bottom-row">
            <li>
              <img src="@/views/WhatsMyIP/images/typing-claw.svg" alt="">
              <div class="copy">
                <h4 class="h6">An Introduction to Encryption</h4>
                <p>Why encryption is important, and why some people think you shouldn’t have it.</p>
                <a href="https://www.tunnelbear.com/blog/an-introduction-to-encryption-how-encryption-protects-all-of-us/">Learn More <span aria-hidden="true">></span></a>
              </div>
            </li>
            <li>
              <img src="@/views/WhatsMyIP/images/getting-started.svg" alt="">
              <div class="copy">
                <h4 class="h6">How to Install TunnelBear on iOS</h4>
                <p>In this tutorial, we’ll show you how to set up TunnelBear for your iPhone and iPad on iOS 12 or newer.</p>
                <a href="https://www.tunnelbear.com/blog/how-to-install-tunnelbear-on-ios/">Learn More <span aria-hidden="true">></span></a>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>

import IPTracker from '@/components/IPTracker/IPTracker.vue'
import spinner from '@/components/spinner/spinner.vue'

export default {
  name: 'whats-my-ip',
  components: {
    IPTracker,
    spinner
  },
  data () {
    return {
      loading: true,
      animating: true,
      error: false
    }
  },
  computed: {
    isLoaded: function () {
      // ensures animation runs for at least duration of setTimeout before page loads
      return !this.loading && !this.animating
    }
  },
  created: function () {
    this.$emit('modifyLayout', {
      hideFooter: true,
      bgColor: 'bg-regular'
    })
    this.timeoutSpinner()
  },
  watch: {
    isLoaded: function (val) {
      if (val === true) {
        this.showFooter()
      }
    }
  },
  methods: {
    showPage: function () {
      this.loading = false
    },
    showFooter: function () {
      this.$emit('modifyLayout', {
        hideFooter: false
      })
    },
    timeoutSpinner: function () {
      setTimeout(() => {
        this.animating = false
      }, 1000)
    },
    showError: function () {
      this.loading = false
      this.error = true
    }
  }
}
</script>

<style src="./WhatsMyIP.styl" scoped lang="stylus">
</style>
