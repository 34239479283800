<template>
  <div id="app" v_cloak v-cloak :class="[layout.bgColor ? 'bg-takeover ' + layout.bgColor : 'bg-dark', {'no-scroll': noScroll}]">
    <nav-bar v-if="!layout.hideNav"
        :minimal="layout.minimalNav"
        :hideCtaButton="layout.hideCtaButton"
        @toggleScroll="noScroll = $event">
    </nav-bar>
    <router-view @modifyLayout="modifyLayout"></router-view>
    <site-footer :detailed="layout.detailedFooter" v-if="!layout.hideFooter"></site-footer>
  </div>
</template>

<script>
import nav from './components/nav/nav'
import footer from './components/footer/footer'

export default {
  name: 'app',
  data () {
    return {
      layout: {},
      noScroll: false
    }
  },
  components: {
    'nav-bar': nav,
    'site-footer': footer
  },
  created: function () {
    this.$router.beforeEach((to, from, next) => {
      this.modifyLayout({})
      next()
    })
  },
  methods: {
    modifyLayout: function (modifier) {
      this.layout = {
        hideFooter: modifier.hideFooter || false,
        hideNav: modifier.hideNav || false,
        detailedFooter: modifier.detailedFooter || false,
        minimalNav: modifier.minimalNav || false,
        bgColor: modifier.bgColor || '',
        navBgColor: modifier.navBgColor || '',
        hideCtaButton: modifier.hideCtaButton || false
      }
    }
  }
}

</script>
<style src="./styles/main.styl" lang="stylus"></style>
