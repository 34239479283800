<template>
  <div class="nav-wrap" :class="{'scrolled': scrolled, 'nav-minimal': minimal}">
    <nav id="main-header">
      <div class="nav-inner clearfix complex">
        <div class="top-bar">
          <a href="https://tunnelbear.com">
            <img class="hidden-xs" src="~@/images/tb-logo-grey.svg" alt="TunnelBear">
            <img class="visible-xs" src="~@/images/t-grey.svg" alt="TunnelBear">
          </a>
          <button id="mobile-menu" @click="toggleMobileMenu()" class="plain" aria-label="menu"></button>
        </div>
        <div class="drawer" :class="{'mobile-open': mobileMenuExpanded}">
          <div class="screen" @click="toggleMobileMenu()" aria-hidden="true"></div>
          <div id="nav-items">
            <div class="mobile-controls">
              <button @click="toggleMobileMenu()" class="plain link right close" aria-label="close navigation"></button>
            </div>
            <div class="nav-menu">
              <div class="nav-item" :class="{'current': current === 'pricing'}" @click="toggleMobileMenu()">
                <a href="https://tunnelbear.com/pricing">Plans</a>
              </div>
              <div class="nav-item" :class="{'current': current === 'apps'}" @click="toggleMobileMenu()">
                <a href="https://tunnelbear.com/apps">Apps</a>
              </div>
              <div class="nav-item">
                <a href="https://help.tunnelbear.com/hc/en-us">Help</a>
              </div>
              <div class="nav-item" :class="{'current': current === 'overview'}" @click="toggleMobileMenu()">
                <a href="https://tunnelbear.com/account/overview">My&nbsp;account</a>
              </div>
              <div class="nav-item bottom" @click="toggleMobileMenu()">
                <a v-if="is.ios()" :href="externalLinks.downloadLinks.ios">
                  <img class="mobile-btn app-store" src="~@/images/app-store.svg" alt="Download on the App Store">
                </a>
                <a v-else href="https://tunnelbear.com/pricing" class="btn">Get&nbsp;TunnelBear</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="nav-inner minimal">
        <div class="top-bar">
          <a href="https://tunnelbear.com/pricing" class="logo">
            <img src="~@/images/tb-logo-grey.svg" alt="TunnelBear">
          </a>
        </div>
      </div>
    </nav>
    <div v-if="is.mobile()" class="mobile-cta-wrap" :class="{'show': mobileScrolled}" v-show="!mobileMenuExpanded">
      <div class="mobile-cta-nav">
        <a href="https://tunnelbear.com" class="logo">
          <img src="~@/images/t-grey.svg" alt="TunnelBear">
        </a>
        <a class="btn" href="https://tunnelbear.com">Get TunnelBear</a>
      </div>
    </div>
  </div>
</template>

<script>
import services from '@/services/services.js'
import is from 'is_js'
import externalLinks from '@/data/external-links.js'

export default {
  name: 'navigation',
  props: {
    minimal: Boolean,
    hideCtaButton: Boolean,
    bgColor: String
  },
  data () {
    return {
      mobileMenuExpanded: false,
      scrolled: false,
      mobileScrolled: false,
      is,
      externalLinks
    }
  },
  computed: {
    current: function () {
      let current = this.$route.name
      if (this.$route.path.indexOf('app') > -1) {
        current = 'apps'
      }
      return current
    }
  },
  created: function () {
    window.addEventListener('scroll', this.calculateNavState)
  },
  methods: {
    toggleMobileMenu: function () {
      this.mobileMenuExpanded = !this.mobileMenuExpanded
      this.$emit('toggleScroll', this.mobileMenuExpanded)
    },
    calculateNavState: function () {
      this.scrolled = services.windowScrollTop() > 50
      this.mobileScrolled = services.windowScrollTop() > 450
    }
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.calculateNavState)
  }
}
</script>

<style lang="stylus" src="./nav.styl">
